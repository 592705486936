import {request} from "@/service/request"
let url = process.env.VUE_APP_WMENZAI_URL // 接口地址

export const getUserInfo = (params) => {
    return request({
        url: url + '/notice/announcementLoginUser',
        method: 'get',
        params
    })
}



/* 公告管理*/
// 获取公告列表
export const getAnnouncementList = (params) => {
    return request({
        url: url + '/notice/getAnnouncementList',
        method: 'get',
        params
    })
}

// 查看某个公告
export const getNoticeViewAnnouncement = (params) => {
    return request({
        url: url + '/notice/viewAnnouncement',
        method: 'get',
        params
    })
}

// 发布公告
export const postNoticeInsertAnnouncement = (params) => {
    return request({
        url: url + '/notice/insertAnnouncement',
        method: 'post',
        data: params
    })
}
// 删除某个公告
export const postNoticeDelAnnouncement = (params) => {
    return request({
        url: url + '/notice/delAnnouncement',
        method: 'post',
        data: params
    })
}


/* 公告管理*/
// 查询员工列表
export const getNoticeQueryUserList = (params) => {
    return request({
        url: url + '/notice/queryUserList',
        method: 'get',
        params
    })
}
// 职位名称列表下拉
export const getNoticeSelectSimpleByPosition = (params) => {
    return request({
        url: url + '/notice/selectSimpleByPosition',
        method: 'get',
        params
    })
}
// 部门名称列表下拉
export const getNoticeSelectSimpleByDepartment = (params) => {
    return request({
        url: url + '/notice/selectSimpleByDepartment',
        method: 'get',
        params
    })
}

// 修改员工信息
export const postNoticeUpdatetUser = (params) => {
    return request({
        url: url + '/notice/updatetUser',
        method: 'post',
        data: params
    })
}


/* 历史记录*/
// 获取历史记录
export const getNoticeQueryHistoryList = (params) => {
    return request({
        url: url + '/notice/queryHistoryList',
        method: 'get',
        params
    })
}

/* 公告详情*/
// 阅读公告
export const postNoticeSubscription = (params) => {
    return request({
        url: url + '/notice/subscription',
        method: 'post',
        data: params
    })
}

// 附件下载
export const getNoticeOssDownload = (params) => {
    return request({
        url: url + '/notice/ossDownload',
        method: 'get',
        responseType: 'blob',
        params
    })
}
export const getTokenDemo = (params) => {
    return request({
        url: url + '/notice/getJsapiTicket',
        method: 'get',
        params
    })
}
